var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_leasing_tracking_report") } },
        [
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                "wrapper-col": { span: 12 },
                "label-col": { span: 8 },
                "label-align": "left"
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.findData(false)
                }
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: { label: _vm.$t(_vm.formRules.unitCode.label) }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.unitCode.decorator,
                                  expression: "formRules.unitCode.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.unitCode.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.unitCode.placeholder
                                ),
                                showSearch: true,
                                allowClear: true,
                                loading: _vm.loading.unitCode,
                                "filter-option": false
                              },
                              on: {
                                search: function(value) {
                                  return _vm.debounce(
                                    _vm.getUnitCode(value),
                                    400
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.dataListUnitCode, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.unitCode } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + data.unitCode) + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s("" + data.unitCode) + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.lessorName.label)
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.lessorName.decorator,
                                  expression: "formRules.lessorName.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.lessorName.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.lessorName.placeholder
                                ),
                                showSearch: true,
                                allowClear: true,
                                loading: _vm.loading.lessorName,
                                "filter-option": false
                              },
                              on: {
                                search: function(value) {
                                  return _vm.debounce(
                                    _vm.getLessorName(value),
                                    400
                                  )
                                }
                              }
                            },
                            _vm._l(_vm.dataListLessorName, function(
                              data,
                              index
                            ) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.id } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + data.fullName) + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s("" + data.fullName) + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(
                              _vm.formRules.leasingContractNumber.label
                            )
                          }
                        },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value:
                                  _vm.formRules.leasingContractNumber.decorator,
                                expression:
                                  "formRules.leasingContractNumber.decorator"
                              }
                            ],
                            attrs: {
                              name: _vm.$t(
                                _vm.formRules.leasingContractNumber.name
                              ),
                              placeholder: _vm.$t(
                                _vm.formRules.leasingContractNumber.placeholder
                              ),
                              autocomplete: "off"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.period.label),
                            "label-align": "right"
                          }
                        },
                        [
                          _c("a-range-picker", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: _vm.formRules.period.decorator,
                                expression: "formRules.period.decorator"
                              }
                            ],
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              placeholder: [
                                _vm.$t("lbl_start_date"),
                                _vm.$t("lbl_end_date")
                              ]
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        {
                          attrs: {
                            label: _vm.$t(_vm.formRules.status.label),
                            "label-align": "right"
                          }
                        },
                        [
                          _c(
                            "a-select",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: _vm.formRules.status.decorator,
                                  expression: "formRules.status.decorator"
                                }
                              ],
                              attrs: {
                                name: _vm.formRules.status.name,
                                placeholder: _vm.$t(
                                  _vm.formRules.status.placeholder
                                ),
                                allowClear: true,
                                "show-search": "",
                                loading: _vm.loading.status,
                                "option-filter-prop": "children",
                                "filter-option": _vm.filterOption
                              }
                            },
                            _vm._l(_vm.dataListStatus, function(data, index) {
                              return _c(
                                "a-select-option",
                                { key: index, attrs: { value: data.value } },
                                [
                                  _c(
                                    "a-tooltip",
                                    [
                                      _c("template", { slot: "title" }, [
                                        _vm._v(
                                          " " + _vm._s("" + data.value) + " "
                                        )
                                      ]),
                                      _vm._v(
                                        " " + _vm._s("" + data.value) + " "
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "a-space",
                        [
                          _c("a-button", { on: { click: _vm.clear } }, [
                            _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                          ]),
                          _c(
                            "a-button",
                            {
                              attrs: {
                                type: "primary",
                                loading: _vm.loading.find,
                                "html-type": "submit"
                              }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-table", {
            attrs: {
              size: "small",
              "data-source": _vm.dataSource,
              columns: _vm.columnsTable,
              scroll: { y: "800px" },
              pagination: {
                showTotal: function(total) {
                  return _vm.$t("lbl_total_items", {
                    total: _vm.totalElements || 0
                  })
                },
                total: _vm.totalElements,
                showSizeChanger: true,
                current: _vm.page,
                pageSizeOptions: _vm.PAGE_SIZE_OPTIONS
              },
              loading: _vm.loading.find
            },
            on: { change: _vm.onChangeTable },
            scopedSlots: _vm._u([
              {
                key: "number",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
                }
              },
              {
                key: "date",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
                }
              },
              {
                key: "nullable",
                fn: function(text) {
                  return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                }
              },
              {
                key: "action",
                fn: function(text, record) {
                  return _c("a-button", {
                    attrs: { icon: "select" },
                    on: {
                      click: function($event) {
                        return _vm.showDetail(record)
                      }
                    }
                  })
                }
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "text-right mt-2" },
            [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.download },
                  on: { click: _vm.download }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            title: _vm.modal.meta.leasingNo || "",
            centered: "",
            width: "75%"
          },
          model: {
            value: _vm.modal.open,
            callback: function($$v) {
              _vm.$set(_vm.modal, "open", $$v)
            },
            expression: "modal.open"
          }
        },
        [
          _c(
            "a-descriptions",
            {
              attrs: {
                bordered: "",
                layout: "vertical",
                column: 4,
                size: "small"
              }
            },
            [
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_leasing_no") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.leasingNo || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_leasing_contract_number") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.modal.meta.leasingContractNumber || "-") +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_unit_code") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.unitCode || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_merk") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.merk || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_type") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.type || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_serial_number") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.serialNumber || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_po_no") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.poNo || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_po_date") } },
                [
                  _vm._v(
                    " " + _vm._s(_vm._f("date")(_vm.modal.meta.poDate)) + " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_dealer_name") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.dealerName || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_tax_invoice_downpayment") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.modal.meta.taxInvoiceDownPaymentNumber || "-"
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                {
                  attrs: { label: _vm.$t("lbl_tax_invoice_downpayment_date") }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("date")(_vm.modal.meta.taxInvoiceDownPaymentDate)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_tax_payment_invoice_no") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.modal.meta.paymentTaxInvoiceNumber || "-") +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_tax_payment_invoice_date") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("date")(_vm.modal.meta.paymentTaxInvoiceDate)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_payment_no") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.paymentNo || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_payment_date") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("date")(_vm.modal.meta.paymentDate)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_payment_type") } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.modal.meta.paymentMethod || "-") + " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_bank_account") } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.modal.meta.bankAccountName || "-") + " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_lessor_name") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.lessorName || "-") + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_leasing_date") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("date")(_vm.modal.meta.leasingDate)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_exchange_rates_variance") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.modal.meta.exchangeRateVariance)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_period") } },
                [
                  _vm._v(
                    " " + _vm._s(_vm.modal.meta.leasingPeriod || "-") + " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_month") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.leasingPeriodMonth) + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_principal_cost") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.modal.meta.principalCost)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_interest") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.modal.meta.interest)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_installment_per_month") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("currency")(_vm.modal.meta.installmentPerMonth)
                      ) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_provision") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.modal.meta.provision)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_residue") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.modal.meta.residue)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_dpp") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.modal.meta.dppPaymentTax)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_ppn") } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.modal.meta.ppnPaymentTax)) +
                      " "
                  )
                ]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: _vm.$t("lbl_notes") } },
                [_vm._v(" " + _vm._s(_vm.modal.meta.notes || "-") + " ")]
              )
            ],
            1
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c("a-button", { on: { click: _vm.toggleModal } }, [
                _vm._v(" " + _vm._s(_vm.$t("lbl_close")) + " ")
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }