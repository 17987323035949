













































































































































































































































































import { debounce } from "@/helpers/debounce";
import { Row, useFindMasterType } from "@/hooks";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataListUnitCodeLeasing,
  DataResponseReportListLeasingTracking,
} from "@/models/interface/leasing.interface";
import { contactServices } from "@/services/contact.service";
import { leasingServices } from "@/services/leasing.service";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

type TableRow = Row<DataResponseReportListLeasingTracking, number>;

@Component
export default class LeasingTracking extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  debounce = debounce;
  moment = moment;
  page = 1;
  limit = 10;
  totalElements = 0;
  dataSource: Array<TableRow> = [];
  dataListUnitCode: Array<DataListUnitCodeLeasing> = [];
  dataListLessorName: Array<ContactData> = [];
  dataListStatus: Array<ResponseListMaster> = [];
  loading = {
    find: false,
    unitCode: false,
    lessorName: false,
    status: false,
    download: false,
  };
  modal = {
    open: false,
    meta: {} as TableRow,
  };

  columnsTable = [
    {
      title: this.$t("lbl_leasing_contract_number"),
      dataIndex: "leasingContractNumber",
      key: "leasingContractNumber",
      width: 250,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
      key: "merk",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_type"),
      dataIndex: "type",
      key: "type",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_po_no"),
      dataIndex: "poNo",
      key: "poNo",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_po_date"),
      dataIndex: "poDate",
      key: "poDate",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_dealer_name"),
      dataIndex: "dealerName",
      key: "dealerName",
      scopedSlots: { customRender: "nullable" },
    },
    {
      key: "action",
      width: "100px",
      align: "right",
      scopedSlots: { customRender: "action" },
    },
  ];
  formRules = {
    leasingContractNumber: {
      label: "lbl_leasing_contract_number",
      name: "lbl_leasing_contract_number",
      placeholder: "lbl_leasing_contract_number",
      decorator: ["leasingContractNumber"],
    },
    unitCode: {
      label: "lbl_unit_code",
      name: "lbl_unit_code",
      placeholder: "lbl_unit_code",
      decorator: ["unitCode"],
    },
    lessorName: {
      label: "lbl_lessor_name",
      name: "lbl_lessor_name",
      placeholder: "lbl_lessor_name",
      decorator: ["lessorName"],
    },
    status: {
      label: "lbl_status",
      name: "lbl_status",
      placeholder: "lbl_status",
      decorator: ["status"],
    },
    period: {
      label: "lbl_period",
      name: "lbl_period",
      placeholder: "lbl_period",
      decorator: ["period"],
    },
  };
  beforeCreate(): void {
    this.form = this.$form.createForm(this, { name: "trackingLeasing" });
  }
  created(): void {
    this.getLessorName("");
    this.getUnitCode("");
    this.getListStatus();
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return ",ALL";
    }
  }

  checkParams(res): string {
    let params = "";
    // company is mandatory
    params += this.$store.state.authStore.authData.companyName;
    params += this.checkValue(`,${res["unitCode"]}`);
    params += this.checkValue(
      `,${
        this.dataListLessorName.find(
          dataFind => dataFind.id === res["lessorName"]
        )?.fullName
      }`
    );

    if (res["period"] && res["period"].length > 0) {
      params += this.checkValue(
        `,${moment(res["period"][0]).format(DEFAULT_DATE_FORMAT)},${moment(
          res["period"][1]
        ).format(DEFAULT_DATE_FORMAT)}`
      );
    } else {
      params += `,ALL`;
      params += `,ALL`;
    }
    params += this.checkValue(`,${res["status"]}`);
    params += this.checkValue(`,${res["leasingContractNumber"]}`);
    return params;
  }

  download() {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {};
      params.params = this.checkParams(res);
      params.search = this.dynamicSearch(res);
      params.sorts = "lessorName:asc,leasingDate:asc";
      this.loading.download = true;
      leasingServices
        .downloadreportListLeasingTracking(params)
        .then(response => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "leasing_tracking.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loading.download = false));
    });
  }

  clear() {
    this.form.resetFields();
  }

  getListStatus(): void {
    this.loading.status = true;
    useFindMasterType("LEASING_STATUS")
      .then(response => {
        this.dataListStatus = response.filter(
          item => item.value.toUpperCase() !== "CANCELLED"
        );
      })
      .finally(() => (this.loading.status = false));
  }

  getUnitCode(valueSearch: string) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (valueSearch) params.search = `unitCode~*${valueSearch}*`;
    this.loading.unitCode = true;
    leasingServices
      .listLeasingUnitCode(params)
      .then(response => (this.dataListUnitCode = response.data))
      .finally(() => (this.loading.unitCode = false));
  }

  getLessorName(value: string) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: `supplierData.supplierType~*Leasing*_AND_supplier~true_AND_active~true`,
    };
    if (value) params.search += `_AND_firstName~*${value}*`;
    this.loading.lessorName = true;
    contactServices
      .listContactData(params)
      .then(response => (this.dataListLessorName = response.data))
      .finally(() => (this.loading.lessorName = false));
  }

  assignSearch(key, value, operator): string {
    if (key === "unitCode" && value) {
      return operator + `unitCode~*${value}*`;
    } else if (key === "lessorName" && value) {
      return operator + `lessorId~*${value}*`;
    } else if (key === "period" && value && value.length > 0) {
      return (
        operator +
        `poDate>=${moment(value[0])
          .set({ hour: 0, minute: 0, second: 0 })
          .format()}_AND_poDate<=${moment(value[1])
          .set({ hour: 23, minute: 59, second: 59 })
          .format()}`
      );
    } else if (key === "status" && value) {
      return operator + `leasingStatus~*${value}*`;
    } else if (key === "leasingContractNumber" && value) {
      return operator + `leasingContractNumber~*${value}*`;
    } else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  findData(pagination: boolean): void {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        page: pagination ? this.page - 1 : 0,
        limit: this.limit,
        sorts: "lessorName:asc,leasingDate:asc",
      };
      if (this.dynamicSearch(res)) {
        params.search = this.dynamicSearch(res);
      }
      this.getData(params);
    });
  }

  getData(params: RequestQueryParamsModel): void {
    this.loading.find = true;
    leasingServices
      .reportListLeasingTracking(params)
      .then(response => {
        this.totalElements = response.totalElements;
        this.dataSource = response.data.map((item, i) => ({
          ...item,
          key: i,
        }));
      })
      .finally(() => (this.loading.find = false));
  }

  showDetail(row: TableRow): void {
    this.modal.meta = row;
    this.toggleModal();
  }

  toggleModal(): void {
    this.modal.open = !this.modal.open;
  }

  onChangeTable(pagination: {
    total: number;
    current: number;
    pageSize: number;
  }): void {
    this.page = pagination.current;
    if (this.limit !== pagination.pageSize) {
      this.page = 1;
    }

    this.limit = pagination.pageSize;
    this.findData(true);
  }
}
